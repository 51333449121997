/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/demirbank';
import { ListTransactions, ListTransactionsFilter } from '../../../../components/transactions/listTransactions/actions';
import {
  DownloadTransactionsAction,
  DownloadTransactionsActionTypes,
  downloadTransactionsFailed,
  downloadTransactionsSucceeded,
  DownloadTransactionsSuccess,
} from './actions';
import { initiateTransactionsExportQuery } from './mutations';

export default function downloadTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: DownloadTransactionsAction) =>
        action.type === DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST,
    ),
    switchMap((action: ListTransactions) =>
      downloadTransactions(action.filter, action.searchString, action.startDate, action.endDate)
        .then((action) => downloadTransactionsSucceeded(action.id, action.url))
        .catch((error) => downloadTransactionsFailed(error)),
    ),
  );
}

export async function downloadTransactions(
  filter: ListTransactionsFilter,
  searchString: string,
  startDate: Date,
  endDate: Date,
): Promise<DownloadTransactionsSuccess> {
  const graphQLClient = await getClient();

  const input = {
    filter,
    query: searchString,
    startDate: new Date(startDate).getTime(),
    endDate: new Date(endDate).getTime(),
  };

  const response = await graphQLClient.query({
    errorPolicy: 'none',
    query: initiateTransactionsExportQuery,
    variables: {
      input,
    },
  });

  const {
    data: {
      initiateTransactionsExport: { id, url },
    },
  } = response;

  return downloadTransactionsSucceeded(id, url);
}
