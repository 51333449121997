/* External dependencies */
import gql from 'graphql-tag';

export const initiateTransactionsExportQuery = gql`
  mutation initiateTransactionsExport($input: ListPaymentsInput!) {
    initiateTransactionsExport(input: $input) {
      id
      url
    }
  }
`;

export const initiateTransactionsExportSubscription = `subscription onFinalizeTransactionsExport($id: ID!) {
                                                        onFinalizeTransactionsExport(id: $id) {
                                                          id
                                                          url
                                                        }
                                                      }`;
