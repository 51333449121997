/* External dependencies */
import { configureStore, Tuple } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import getAppClientEpic from '../components/appClients/getAppClient/redux/epic';
import getAppClient from '../components/appClients/getAppClient/redux/reducer';
import {
  changePasswordEpic,
  confirmPasswordEpic,
  forgotPasswordEpic,
  initClientEpic,
  loginEpic,
  logoutEpic,
} from '../components/login/redux/epics';
import login, { LoginState } from '../components/login/redux/reducer';
import activeServiceEpic from '../components/services/activeService/epics';
import activeService from '../components/services/activeService/reducer';
import blockServiceEpic from '../components/services/blockService/epics';
import blockService from '../components/services/blockService/reducer';
import createServiceEpic from '../components/services/createService/redux/epics';
import createService from '../components/services/createService/redux/reducer';
import { deleteCategoryEpic, deleteServiceEpic } from '../components/services/deleteService/redux/epics';
import deleteService from '../components/services/deleteService/redux/reducer';
import { getServiceEpic, setServiceState } from '../components/services/getService/redux/epic';
import getService from '../components/services/getService/redux/reducer';
import listServicesEpic from '../components/services/listServices/epics';
import services from '../components/services/listServices/reducer';
import processService from '../components/services/processService/redux/reducer';
import createServiceProviderEpic from '../components/services/serviceProviders/createServiceProvider/redux/epics';
import createServiceProvider from '../components/services/serviceProviders/createServiceProvider/redux/reducer';
import deleteServiceProviderEpic from '../components/services/serviceProviders/deleteServiceProvider/redux/epics';
import deleteServiceProvider from '../components/services/serviceProviders/deleteServiceProvider/redux/reducer';
import getServiceProviderServiceReducer from '../components/services/serviceProviders/getServiceProviderService/redux/reducer';
import listServiceProvidersEpic from '../components/services/serviceProviders/listServiceProviders/redux/epics';
import serviceProviders from '../components/services/serviceProviders/listServiceProviders/redux/reducer';
import {
  getServiceProviderEpic,
  updateServiceProviderEpic,
} from '../components/services/serviceProviders/updateServiceProvider/redux/epics';
import updateServiceProvider from '../components/services/serviceProviders/updateServiceProvider/redux/reducer';
import { updateServiceEpic } from '../components/services/updateService/redux/epics';
import updateService from '../components/services/updateService/redux/reducer';
import downloadTransactionsEpic from '../components/transactions/exportTransactions/redux/epics';
import downloadTransactions from '../components/transactions/exportTransactions/redux/reducer';
import listTransactionsEpic from '../components/transactions/listTransactions/epics';
import transactions from '../components/transactions/listTransactions/reducer';
import createUserEpic from '../components/users/createUser/redux/epics';
import newUser from '../components/users/createUser/redux/reducer';
import listUsersEpic from '../components/users/listUsers/redux/epics';
import users from '../components/users/listUsers/redux/reducer';
import { getUserEpic, updateUserEpic } from '../components/users/updateUser/redux/epics';
import user from '../components/users/updateUser/redux/reducer';

export type ApplicationState = {
  login: LoginState;
};

const rootEpic = combineEpics(
  activeServiceEpic,
  blockServiceEpic,
  createServiceProviderEpic,
  createServiceEpic,
  changePasswordEpic,
  createUserEpic,
  deleteServiceProviderEpic,
  deleteCategoryEpic,
  deleteServiceEpic,
  downloadTransactionsEpic,
  getAppClientEpic,
  getServiceEpic,
  getServiceProviderEpic,
  getUserEpic,
  updateUserEpic,
  setServiceState,
  initClientEpic,
  listServiceProvidersEpic,
  listServicesEpic,
  listTransactionsEpic,
  listUsersEpic,
  loginEpic,
  forgotPasswordEpic,
  confirmPasswordEpic,
  logoutEpic,
  updateServiceEpic,
  updateServiceProviderEpic,
);

let store;

export function createStore() {
  const epicMiddleware = createEpicMiddleware();

  store = configureStore({
    reducer: {
      activeService,
      blockService,
      createService,
      createServiceProvider,
      deleteServiceProvider,
      getAppClient,
      getService,
      processService,
      login,
      deleteService,
      downloadTransactions,
      serviceProviders,
      services,
      updateService,
      updateServiceProvider,
      users,
      user,
      newUser,
      transactions,
      getServiceProviderServiceReducer,
    },
    middleware: () => new Tuple(epicMiddleware),
  });

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
